import React from "react"

import styled from "styled-components"

const Entrega = () => (
  <StyledEntrega>
    <h1 className="MSB">ENTREGAMOS<br />
        VINHOS EM<br />
        SUA CASA<br />
    </h1>
    <h4 className="MSM">
        ENTREGA NO DISTRITO DE AVEIRO<br/>
        DE FORMA SEGURA E RÁPIDA, À<br/>
        PORTA DE SUA CASA, UMA<br/>
        SELEÇÃO DE VINHOS<br/>
        PORTUGUESES
    </h4>
  </StyledEntrega>
)

export default Entrega

const StyledEntrega = styled.div`
    text-align: center;
    padding: 15vw 0;
    color: #3e3027;
    background-color: white;
    width: 100vw;
    max-width: 1000px;
    .MSM{
        margin-top: 5vw;
    }
    @media screen and (min-width: 1000px){
      padding: 100px 0 150px;
      .MSM{
        margin-top: 50px;
      }
    }
`
