import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"

import logo from "../images/logo.svg"

const Intro = ({ data }) => (
  <StyledIntro>
    <Img fluid={data.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "contain" }}/>
    <div className="logo">
      <img src={logo} alt="logo" className="logoImg"/>
    </div>
  </StyledIntro>
)

export default Intro

const StyledIntro = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  .logo{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 9vw);
    background: white;
    border-radius: 100%;
    width: 35vw;
    height: 35vw;
    .logoImg{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (min-width: 1000px){
    .logo{
      width: 350px;
      height: 350px;
      transform: translate(-50%, 27%);
    }
  }
`
