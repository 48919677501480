import React from "react"

import styled from "styled-components"

import logo from "../images/ftr_logo.svg"
import face from "../images/face.svg"
import insta from "../images/insta.svg"
import linked from "../images/linked.svg"

const Footer = () => (
  <StyledFooter>
      <img src={logo} className="logo" alt="Logo da Saven" />
      <div className="social">
          <a href="https://www.linkedin.com/company/luacheiavv/"><img src={linked} className="linked socialIcon" alt="Logo do LinkedIn" /></a>
          <a href="https://www.facebook.com/LuaCheiaSaven/"><img src={face} className="face socialIcon" alt="Logo do Facebook" /></a>
          <a href="https://www.instagram.com/luacheia_saven/"><img src={insta} className="insta socialIcon" alt="Logo do Instagram" /></a>
      </div>
      <p className="AGSB">Zona Industrial da Mota, Rua 10<br/>Lote E40 - Apartado 51<br/>3834-907 Gafanha da Encarnação</p>
  </StyledFooter>
)

export default Footer

const StyledFooter = styled.div`
    background-color: #fff9f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12vw 0;
    text-align: center;
    width: 100%;
    max-width: 768px;
    .logo{
        width: 35%;
    }
    .social{
        display: flex;
        margin: 10vw 0;
        .socialIcon{
            margin: 0 2.5vw;
            height: 5vw;
            max-height: 30px;
        }
    }
    @media screen and (min-width: 600px){
        padding: 80px 0;
        .social{
            margin: 50px 0;
            .socialIcon{
                margin: 0 15px;
            }
        }
    }

`
