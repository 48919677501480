import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"

const Lista = ({data}) => (
  <StyledLista>
      <div className="verde">
          <h2 className="AGSD">Vinho Verde</h2>
          <div className="cards">
            {data.verde.map((data, i)=>(
                <div key={i} className="card">
                    <Img fluid={data.img.childImageSharp.fluid} style={{ width: `25%` }} imgStyle={{ objectFit: "contain" }}/>
                    <div className="details">
                        <p className="NSB title">{data.nome}</p>
                        <p className="NSB red smaller">{data.regiao}</p>
                        <p className="NSR small"><b>Vinificação:</b> {data.vinificacao}</p>
                        <p className="NSR small"><b>Tipo:</b> {data.tipo}</p>
                        <p className="NSR small"><b>Castas:</b> {data.castas}</p>
                        <p className="NSR small"><b>Produtor:</b> {data.produtor}</p>
                        <p className="NSR small"><b>Enólogo:</b> {data.enologo}</p>
                        <p className="NSR small"><b>Ano:</b> {data.ano}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
      <div className="douro">
          <h2 className="AGSD">Douro</h2>
          <div className="cards">
            {data.douro.map((data, i)=>(
                <div key={i} className="card">
                    <Img fluid={data.img.childImageSharp.fluid} style={{ width: `25%` }} imgStyle={{ objectFit: "contain" }}/>
                    <div className="details">
                        <p className="NSB title">{data.nome}</p>
                        <p className="NSB red smaller">{data.regiao}</p>
                        <p className="NSR small"><b>Vinificação:</b> {data.vinificacao}</p>
                        <p className="NSR small"><b>Tipo:</b> {data.tipo}</p>
                        <p className="NSR small"><b>Castas:</b> {data.castas}</p>
                        <p className="NSR small"><b>Produtor:</b> {data.produtor}</p>
                        <p className="NSR small"><b>Enólogo:</b> {data.enologo}</p>
                        <p className="NSR small"><b>Ano:</b> {data.ano}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
      <div className="dao">
          <h2 className="AGSD">Dão</h2>
          <div className="cards">
            {data.dao.map((data, i)=>(
                <div key={i} className="card">
                    <Img fluid={data.img.childImageSharp.fluid} style={{ width: `25%` }} imgStyle={{ objectFit: "contain" }}/>
                    <div className="details">
                        <p className="NSB title">{data.nome}</p>
                        <p className="NSB red smaller">{data.regiao}</p>
                        <p className="NSR small"><b>Vinificação:</b> {data.vinificacao}</p>
                        <p className="NSR small"><b>Tipo:</b> {data.tipo}</p>
                        <p className="NSR small"><b>Castas:</b> {data.castas}</p>
                        <p className="NSR small"><b>Produtor:</b> {data.produtor}</p>
                        <p className="NSR small"><b>Enólogo:</b> {data.enologo}</p>
                        <p className="NSR small"><b>Ano:</b> {data.ano}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
      <div className="alentejo">
          <h2 className="AGSD">Alentejo</h2>
          <div className="cards">
            {data.alentejo.map((data, i)=>(
                <div key={i} className="card">
                    <Img fluid={data.img.childImageSharp.fluid} style={{ width: `25%` }} imgStyle={{ objectFit: "contain" }}/>
                    <div className="details">
                        <p className="NSB title">{data.nome}</p>
                        <p className="NSB red smaller">{data.regiao}</p>
                        <p className="NSR small"><b>Vinificação:</b> {data.vinificacao}</p>
                        <p className="NSR small"><b>Tipo:</b> {data.tipo}</p>
                        <p className="NSR small"><b>Castas:</b> {data.castas}</p>
                        <p className="NSR small"><b>Produtor:</b> {data.produtor}</p>
                        <p className="NSR small"><b>Enólogo:</b> {data.enologo}</p>
                        <p className="NSR small"><b>Ano:</b> {data.ano}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
      <div className="azeite">
          <h2 className="AGSD">Azeites</h2>
          <div className="cards">
            {data.azeite.map((data, i)=>(
                <div key={i} className="card">
                    <Img fluid={data.img.childImageSharp.fluid} style={{ width: `25%` }} imgStyle={{ objectFit: "contain" }}/>
                    <div className="details">
                        <p className="NSB title">{data.nome}</p>
                        <p className="NSB red smaller">{data.regiao}</p>
                        <p className="NSR small"><b>Produtor:</b> {data.produtor}</p>
                        <p className="NSR small"><b>Variedades:</b> {data.variedades}</p>
                        <p className="NSR small"><b>Processo de Extracção:</b> {data.processo}</p>
                        <p className="NSR small"><b>Cor:</b> {data.cor}</p>
                        <p className="NSR small"><b>Aroma:</b> {data.aroma}</p>
                        <p className="NSR small"><b>Paladar:</b> {data.paladar}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
  </StyledLista>
)

export default Lista

const StyledLista = styled.div`
    background-color: #fff9f4;
    .AGSD{
        text-align: center;
        color: #3e3026;
    }
    .verde{
        padding-top: 15vw;
        padding-bottom: 5vw;
        border-left: solid #f4e3b4 4vw;
        border-right: solid #f4e3b4 4vw;
    }
    .douro{
        padding-top: 15vw;
        padding-bottom: 5vw;
        border-left: solid #b24b70 4vw;
        border-right: solid #b24b70 4vw;
    }
    .dao{
        padding-top: 15vw;
        padding-bottom: 5vw;
        border-left: solid #92a5c1 4vw;
        border-right: solid #92a5c1 4vw;
    }
    .alentejo{
        padding-top: 15vw;
        padding-bottom: 5vw;
        border-left: solid #b6cc93 4vw;
        border-right: solid #b6cc93 4vw;
    }
    .azeite{
        padding-top: 15vw;
        padding-bottom: 5vw;
        border-left: solid #222222 4vw;
        border-right: solid #222222 4vw;
    }
    .card{
        margin: 15vw 2vw;
        display: flex;
        align-items: flex-end;
    }
    .details{
        width: 75%;
        color: #3e3025;
        margin-bottom: 2vw;
        margin-left: 3vw;
    }
    .title{
        text-transform: uppercase;
        color: #3e3025;
        margin-bottom: 0.4em;
    }
    .red{
        color: #fa4f4f;
    }
    .smaller{
        margin-bottom: 1.6em;
    }

  @media screen and (min-width: 550px){
    .title{
        font-size: 16px;
    }
    .smaller{
        font-size: 12px;
    }
    .small{
        font-size: 14px;
    }

    .verde{
        padding-top: 10vw;
        padding-bottom: 5vw;
        border-left: solid #f4e3b4 10vw;
        border-right: solid #f4e3b4 10vw;
    }
    .douro{
        padding-top: 10vw;
        padding-bottom: 5vw;
        border-left: solid #b24b70 10vw;
        border-right: solid #b24b70 10vw;
    }
    .dao{
        padding-top: 10vw;
        padding-bottom: 5vw;
        border-left: solid #92a5c1 10vw;
        border-right: solid #92a5c1 10vw;
    }
    .alentejo{
        padding-top: 10vw;
        padding-bottom: 5vw;
        border-left: solid #b6cc93 10vw;
        border-right: solid #b6cc93 10vw;
    }
    .azeite{
        padding-top: 10vw;
        padding-bottom: 5vw;
        border-left: solid #222222 10vw;
        border-right: solid #222222 10vw;
    }
  }

  @media screen and (min-width: 750px){
    .title{
        font-size: 20px;
    }
    .smaller{
        font-size: 14px;
    }
    .small{
        font-size: 16px;
    }
  }

  @media screen and (min-width: 768px){
    .verde{
        padding-top: 60px;
        padding-bottom: 40px;
        border-left: solid #f4e3b4 3vw;
        border-right: solid #f4e3b4 3vw;
    }
    .douro{padding-top: 50px;
        padding-bottom: 20px;
        border-left: solid #b24b70 3vw;
        border-right: solid #b24b70 3vw;
    }
    .dao{padding-top: 50px;
        padding-bottom: 20px;
        border-left: solid #92a5c1 3vw;
        border-right: solid #92a5c1 3vw;
    }
    .alentejo{padding-top: 50px;
        padding-bottom: 20px;
        border-left: solid #b6cc93 3vw;
        border-right: solid #b6cc93 3vw;
    }
    .azeite{padding-top: 50px;
        padding-bottom: 20px;
        border-left: solid #222222 3vw;
        border-right: solid #222222 3vw;
    }

    .cards{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .card{
        margin: 5vw 2vw;   
    }
  }
`
