import React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Intro from "../components/intro"
import Entrega from "../components/entrega"
import Mapa from "../components/mapa"
import Lista from "../components/lista"
import Contacto from "../components/contacto"
import Popup from "../components/popup"
import Footer from "../components/footer"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <StyledMobile>
      <Intro data={data.indexJson.intro} />
      <Entrega />
      <Mapa data={data.indexJson.localidades} />
      <Lista data={data.vinhosJson} />
      <Contacto />
      <Popup/>
      <Footer />
    </StyledMobile>
  </Layout>
)

export default IndexPage



export const Json = graphql`
  query landing {
    indexJson {
      intro {
        childImageSharp {
          fluid(quality: 70, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      localidades
    }
    vinhosJson {
      verde{
        nome
        vinificacao
        tipo
        castas
        produtor
        enologo
        regiao
        ano
        img {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      douro{
        nome
        vinificacao
        tipo
        castas
        produtor
        enologo
        regiao
        ano
        img {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      dao{
        nome
        vinificacao
        tipo
        castas
        produtor
        enologo
        regiao
        ano
        img {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      alentejo{
        nome
        vinificacao
        tipo
        castas
        produtor
        enologo
        regiao
        ano
        img {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      azeite{
        nome
        regiao
        produtor
        variedades
        processo
        cor
        aroma
        paladar
        gastronomia
        acidez
        peroxidos
        ceras
        k232
        k268
        k
        img {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const StyledMobile = styled.div`
    background-color: #fff9f4;
    display: flex;
    flex-direction: column;
    align-items: center;
`