import React from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';

const PopupComponent = loadable(() => import('reactjs-popup'), {
  ssr: false,
});

const StyledPopupComponent = () => {
  return (
    <StyledPopup 
      open
      modal
      nested
      lockScroll
    >
      {close => (
        <div className='modal'>
          <h1 className='AGSD'>Aviso Legal</h1>
          <h5 className='NSR'>Antes de visitar o nosso website precisamos que confirme se tem a idade legal para consumo de bebidas alcóolicas (mais de 18 anos).</h5>
          <div className='buttons'>
            <button
              className="NSR button-yes"
              onClick={() => {
                close();
              }}
            >
              Sim
            </button>
            <button
              className="NSR button-no"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open('https://saven.pt', '_self');
                }
              }}
            >
              Não
            </button>
          </div>
        </div> 
      )}
    </StyledPopup>
  );
}

export default StyledPopupComponent;

const StyledPopup = styled(PopupComponent)`
  &-content {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 248, 244, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
      max-width: 500px;
      text-align: center;

      h1 {
        font-size: 20px;
        color: #3e3028;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      h5 {
        font-size: 30px;
        color: #3e3028;
        margin-bottom: 30px;
      }

      .buttons {
        display: flex;
        justify-content: center;

        button {
          font-size: 25px;
          color: #3e3028;
          padding: 8px 45px;
          border: 1px solid #3e3028;
          margin: 0 10px;
          transition: background-color 0.3s ease;

          :hover {
            background: #3e3028;
            color: #fff;
          }
        }
      }
    }
  }
`;
