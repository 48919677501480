import React from "react"

import styled from "styled-components"

import phone from "../images/phone.svg"
import mail from "../images/mail.svg"
import bottle from "../images/bottle.svg"

const Contacto = () => (
  <StyledContacto id="contactos">
      <a href="tel:+351234329530" className="link">
        <img src={phone} className="phoneIcon" alt="Telefone" />
        <h1 className="NSR">+351 234 329 530</h1>
      </a>
      <a href="mailto:saven@saven.pt" className="link">
        <img src={mail} className="mailIcon" alt="Email" />
        <h1 className="NSR">saven@saven.pt</h1>
      </a>

      <img src={bottle} className="bottleIcon" alt="Garrafa" />
      <h3 className="MSB">ENCOMENDE JÁ</h3>
      <p className="AGSD">ENTREGAMOS EM TODO O<br/>DISTRITO DE AVEIRO</p>
  </StyledContacto>
)

export default Contacto

const StyledContacto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12vw 0;
  color: #3e3027;
  text-align: center;
  background-color: white;
  width: 100%;
  max-width: 1000px;
  .link{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5vw;
      img{
          margin: 3vw 0;
      }
  }
  .phoneIcon{
    width: 10vw;
    max-width: 100px;
  }
  .mailIcon{
    width: 10vw;
    max-width: 100px;
  }
  .bottleIcon{
    height: 16vw;
    max-height: 160px;
    margin-top: 12vw;
  }
  .MSB{
    color: #3e3026;
    margin: 3vw 0;
  }
  .NSR{
    font-size: calc(26px + 9 * (100vw - 300px) / 116);
  }

  @media screen and (min-width: 416px){
    .NSR{
      font-size: 35px;
    }
  }

  @media screen and (min-width: 1000px){
    padding: 70px 0;
    .bottleIcon{
      margin-top: 50px;
    }
    .MSB{
      margin: 20px 0;
    }
  }
`
