import React from "react"

import styled from "styled-components"

import grapes from "../images/grapes.svg"
import glass from "../images/glass.svg"
import map from "../images/map.svg"

const Mapa = ({ data }) => (
  <StyledMapa>
    <img src={grapes} className="grapes" alt="Cacho de uvas" />
    <a href="/#contactos" className="faixaCopo">
        <img src={glass} className="glass" alt="Copo de vinho" />
    </a>
    <div className="combo">
        <div className="lista">
            {data.map((data ,i)=>(
                <h3 key={i} className="localidades NSBI">{data}</h3>
            ))}
        </div>
        <div className="mapa">
            <img src={map} className="mapaImg" alt="Mapa da Região" />
        </div>
    </div>
  </StyledMapa>
)

export default Mapa

const StyledMapa = styled.div`
    background-color: #fff9f4;
    position: relative;
    width: 100%;
    max-width: 1000px;
    .grapes{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25%;
    }
    .faixaCopo{
        width: 100%;
        height: 20vw;
        display: flex;
        justify-content: flex-end;
        padding: 4vw;
        .glass{
            height: 100%;
        }
    }
    .combo{
        display: flex;
        justify-content: space-between;
        padding: 10vw 0;
        .lista{
            width: 50%;
            padding-left: 9%;
            .localidades{
                color: #707070;
            }
        }
        .mapa{
            width: 50%;
            padding-right: 5%;
            padding-left: 3%;
            display: flex;
            align-content: center;
            .mapaImg{
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 550px){
        .faixaCopo{
            padding: 4vw 10vw;
        }
        .combo{
            .lista{
                padding-left: 10vw;
            }
            .mapa{
                padding-right: 10%;
            }
        }
    }

    @media screen and (min-width: 1000px){
        .faixaCopo{
            height: 200px;
            padding: 40px 50px;
        }
        .combo{
            padding: 50px 0;
            .lista{
                padding-left: 100px;
            }
            .mapa{
                padding-right: 100px;
            }
        }
    }
`
